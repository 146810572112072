import axios from "axios";

const client = axios.create({
    // withCredentials: true,
    baseURL:  'https://azaza.com',
    headers: {
        "Content-type": "application/x-www-form-urlencoded"
      }
});

export const subscribeAPI = {
    
    subscribeMint(email = '') {
        const params = new URLSearchParams();
        params.append('email', email);
        return client
                .post('msubscribe.php', params)
                .then(response => response.data)
                .catch(e=>console.log(e));
    }
};