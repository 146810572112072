import React from 'react';
import s from './Nav.module.scss';
// import logo from '../../resources/icons/logo/logo.png';
import { motion } from "framer-motion";
import NavItem from './navItem/NavItem';

import MarketplaceBttn from './marketplaceBttn/MarketplaceBttn';
import { useSelector } from 'react-redux';

const Nav = (props) => {
    const { goToPage, currentPage, inProgress, bgColor = '' } = props;
    const menuItems = useSelector(store => store.menu.desctop.items);
    const items = menuItems.map( ( el, id ) => <NavItem key={id} id={id} {...el} goToPage={goToPage} currentPage={currentPage} /> );
    // let logoImgStyle = {};
    let navStyles = s.nav;
    if (currentPage !== 0 || inProgress !== 0) {
        navStyles = s.nav + ' ' + s.navSecondaty;
    }

    let bgColorStyle = {};
    if(bgColor) bgColorStyle = { background: bgColor };

    return (
        <div className={s.main}>
            <motion.div
                initial="hidden"
                whileInView="visible"
                // viewport={{ once: true }}
                transition={{ delay: 0.01, duration: 0.2 }}
                variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 1, opacity: 0 }
                }}>

                <nav className={navStyles} id='nav' style={ bgColorStyle } >
                    {/* <div className={s.logo}>
                        <img className={s.logoimg} src={logo} alt="RageArena" style={logoImgStyle} />
                    </div> */}
                    <div className={s.nav__menu}>

                        {items}

                        <div className={s.marketplaceBttn}>
                            <MarketplaceBttn title='Marketplace' url='/comingsoon' />
                        </div>

                    </div>
                </nav>

            </motion.div>
        </div>
    )
}

export default Nav;