import React from 'react';
import ContactAdressGorizontalList from '../../items/contactAdressGorizontalList/ContactAdressGorizontalList';
import s from './ContractAdressPanel.module.scss';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

const ContractAdressPanel = () => {

    const contractAdressess = useSelector(store => store.contractAdressess);
    const titleInLine = false;//useMediaQuery({ query: '(min-width: 950px)' });
    const panels = contractAdressess.map((el, id) => <ContactAdressGorizontalList key={id} {...el} listId={id} titleInLine={titleInLine} />);

    return (
        <div className={s.main}>
            <motion.div
                id='contractAdressPanel'
                className={s.motion__cont}
                initial="hidden"
                whileInView="visible"
                // viewport={{ once: true }}
                transition={{ delay: .01, duration: 0.1 }}
                variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 30, opacity: 0 }
                }}> {panels} </motion.div>
        </div>
    );
}

export default ContractAdressPanel;