import React from 'react';
import s from './SkillItem.module.scss';
import arrow from '../../../../resources/images/team/green_arrow.png';

const SkillItem = ({ skill }) => {
    
    return (
        <div className={s.main} >
            <div className={s.content}>
                <img className={s.arrow} src={arrow} alt="" />
                <div className={s.skill}>{ skill }</div>
            </div>
        </div>
    )
}

export default SkillItem;