import initialState from "../initialState";

const SET_COUNT = "SET_COUNT";
const INC_LOADED = "INC_LOADED";

const loaderProgress = (state = initialState.loaderProgress, action) => {
    switch(action.type) {
        case SET_COUNT:
            return {
                ...state,
                count: action.payload
            }
        case INC_LOADED:
            return {
                ...state,
                loaded:  (state.loaded < state.count) ? state.loaded+1 : state.loaded
            }
        default: return state;
    }
}

export default loaderProgress;

export const setCount = (count) => ({type:SET_COUNT, payload:count})
export const incLoaded = () => ({type:INC_LOADED})