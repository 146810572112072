import React, { useEffect, useRef, useState} from 'react';
import { InView } from 'react-intersection-observer';
import s from './Intro.module.scss';
import sample from '../../resources/video/gameplay.mp4';
// import poster from '../../resources/video/gameplay.png';


const Intro = ({id}) => {
    const videoRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        // let isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended 
        //         && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        if (isVisible) {

            const playPromise = videoRef.current.play();
            if (playPromise !== null) {
                playPromise.catch(() => { videoRef.current.play(); })
            }
            
        } else {
            
            // if (isPlaying) {
                setTimeout(() => {
                    videoRef.current.pause()
                }, 400);
            // }

        }

    }, [isVisible]);

    return (

        <InView as="div" threshold='1' onChange={(inView, entry) => setIsVisible(inView)}>
            <div className = {s.intro} id={id} >
                {/* poster={poster} autoPlay */}
                <video className={s.videoTag} ref={videoRef} loop muted preload='auto' controlsList="nodownload">
                    <source src={sample} type='video/mp4' />
                </video>
            </div>
        </InView>

    )
}

export default Intro;