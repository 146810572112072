import React from 'react';
import s from './Footer.module.scss';

import logo from '../../resources/icons/logo/logo.png';
import HorizontalSocialLinksPanel from '../panels/horizontalSocialLinksPanel/HorizontalSocialLinksPanel';
import FooterNavPanel from './footerNavPanel/FooterNavPanel';
import { useSelector } from 'react-redux';
import PLinkCont from './pLinkCont/PLinkCont';
import GLinkCont from './gLinkCont/GLinkCont';

const Footer = ({ goToPage, currentPage, inProgress }) => {
    const menuItems = useSelector(store => store.menu.desctop.items);
    const footerData = useSelector(store => store.footer);
    return (
        <div id='footer' className={s.main}>

            <div className={ s.content }>

                <div className={ s.logoCont }>
                    <img src={logo} alt="Logo" className={s.logo} />
                    <div className={s.copyright}>{footerData.copyrightText}</div>
                </div>

                <div className={ s.infoCont }>

                    <div className={ s.infoCont_row1 }>

                        <div className={s.navPanel}>
                            <FooterNavPanel
                                goToPage={goToPage}
                                currentPage={currentPage}
                                inProgress={inProgress}
                                menuItems={menuItems}
                            />
                        </div>

                        <div className={s.socialLinkPanel}>
                            <HorizontalSocialLinksPanel title={'JOIN OUR COMMUNITY:'}/>
                        </div>

                    </div>
                    <div className={ s.infoCont_row2 }>
                        <hr className={ s.hr1 }/>
                    </div>
                    <div className={ s.infoCont_row3 }>
                        <PLinkCont {...footerData.partnershipInquiry} />
                        <GLinkCont {...footerData.gameSupport}/>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Footer;