import { useMediaQuery } from 'react-responsive';
import './App.scss';
// import SocialLinksPanel from './components/panels/socialLinksPanel/SocialLinksPanel';
import { Routes, Route } from "react-router-dom";
// import MainPageContentLoader from './components/mainPageContentLoader/MainPageContentLoader';
// import Scroller from './components/scroller/Scroller';
import ComingSoon from './components/comingsoon/ComingSoon';
// import Loader from './components/loader/Loader';
import MainPageContentLoader from './components/mainPageContentLoader/MainPageContentLoader';

function App() {
  
  // добавить в .env когда будет сдн
  // PUBLIC_URL=https://cdn.something.com

  //https://stackoverflow.com/questions/54491645/media-query-syntax-for-reactjs

  // https://github.com/yocontra/react-responsive
  // const isMobileSmall = useMediaQuery({ query: '(max-width: 325px)' });
  // const isMobileMid = useMediaQuery({ query: '(max-width: 375px)' });
  // const isMobileFloor = useMediaQuery({ query: '(max-width: 425px)' });

  // const isTabletFloor = useMediaQuery({ query: '(max-width: 426px)' });
  // const isTabletMid = useMediaQuery({ query: '(max-width: 768px)' });
  const isTabletCeil = useMediaQuery({ query: '(max-width: 1024px)' });

  // const isLaptopFloor = useMediaQuery({ query: '(max-width: 1025px)' });
  // const isLaptopCeil = useMediaQuery({ query: '(max-width: 1440px)' });

  // const isXHDFloor = useMediaQuery({ query: '(max-width: 1441px)' });
  // const isXHDCeil = useMediaQuery({ query: '(max-width: 4096px)' });

  // const socialLinksDatas = useSelector(state => state.socialLinksDatas);

  // let socLinkType = 'desctop';
  // if(isTabletMid) socLinkType = 'mob';

  let className = "App";
  if(isTabletCeil) className = 'App mob';
  
  return (
    <div className={ className }>
      {/* <div>{window.innerWidth}</div> */}

      {/* {!isMobileSmall && <SocialLinksPanel type={socLinkType} />} */}

      <Routes>
          <Route path='/'>
            <Route exact path="" element={ <MainPageContentLoader /> } />
            <Route exact path=":goTo" element={ <MainPageContentLoader /> } />
          </Route>
          
          <Route exact path="/comingsoon" element={ <ComingSoon /> } />
          {/* <Route exact path="/loader" element={ <Loader /> } /> */}
      </Routes>

    </div>
  );
}

export default App;
