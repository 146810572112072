import React from "react";
import RoadmapItem from "../roadmapItem/RoadmapItem";
import s from './RoadmapPage.module.scss';

const RoadmapPage = ({ title, items }) => {

    const rItems = items.map((el, id) => <RoadmapItem key={id} {...el} pos={id} style={{marginTop: (20 - (id*3)) + 'em'}} />);

    return (
        <div className={s.main}>
                {/* <div className={s.title}>{ title }</div>*/}
            <div className={s.content}>

                { rItems }

            </div>
        </div>
    )

}

export default RoadmapPage;