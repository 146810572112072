import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import s from './ComingSoon.module.scss';

import Nav from "../nav/Nav";
import Mobnav from "../nav/Mobnav";
import HorizontalSocialLinksPanel from '../panels/horizontalSocialLinksPanel/HorizontalSocialLinksPanel';
import PLinkCont from '../footer/pLinkCont/PLinkCont';
import GLinkCont from '../footer/gLinkCont/GLinkCont';

import logo from '../../resources/icons/logo/logo.png';
import SubscribeForm from './form/SubscribeForm';

const ComingSoon = () => {
    const isTabletCeil = useMediaQuery({ query: '(max-width: 1024px)' });

    const navigate = useNavigate();
    const data = useSelector(store => store.comingsoon);
    const footerData = useSelector(store => store.footer);
    return (
        <div className={s.main} >

            {isTabletCeil && (
            <Mobnav
                goToPage={(page)=>{ navigate('/', {replace: false, state:{goTo:page}}); }}
                currentPage={0}
                inProgress={0}
            />
            )}
            {!isTabletCeil && (
            <Nav
                goToPage={(page)=>{ navigate('/', {replace: false, state:{goTo:page}}); }}
                currentPage={0}
                inProgress={0}
                bgColor={'rgba(20, 17, 59, 0.2)'} // rgba(33, 37, 78, 0.2)
            />
            )}

            <div className={s.content}>
                <div className={s.title}>{ data.title }</div>
                <div className={s.description}>{ data.desc }</div>
                <div className={s.formCont}>
                    <SubscribeForm />
                </div>
            </div>

            <div className={s.footer}>

                <hr className={ s.hr1 }/>
                <div className={s.footerCont}>
                    <div className={ s.logoCont }>
                        <img src={logo} alt="Lost Oasis" className={s.logo} />
                    </div>

                    <div className={s.linkCont}>
                        <div className={s.plinkcont}>
                            <PLinkCont {...footerData.partnershipInquiry} />
                        </div>
                        <div className={s.glinkcont}>
                            <GLinkCont {...footerData.gameSupport}/>
                        </div>
                    </div>

                    <div className={s.socialLinkPanel}>
                        <HorizontalSocialLinksPanel title={'JOIN OUR COMMUNITY:'}/>
                    </div>
                </div>
               
            </div>

        </div>
    )
}

export default ComingSoon;