import React from 'react';
import s from './PLinkItem.module.scss';

const PLinkItem = ({ title, separator, linkItems }) => {
    const items = linkItems.map((el, id) => {
        return (
            <div key={id}>
                {id > 0 && " " + separator + " "}
                <a href={el.url}> {el.text}</a>
            </div>
        )
    });
    return (
        <div className={s.main} >
            <div className={s.content}>
                <div className={s.title}>{ title }</div>
                <div className={s.itemsCont}>{ items }</div>
            </div>
        </div>
    )
}

export default PLinkItem;