import React from 'react';
import s from './Mobnav.module.scss';
import { connect } from "react-redux";
import { slide as Menu } from 'react-burger-menu'

// import MobMarketplaceBttn from './mobMarketplaceBttn/MobMarketplaceBttn';
import MobNavItem from './mobNavItem/MobNavItem';

// import burgerIcon from '../../resources/icons/menu/menu.svg';

class Mobnav extends React.Component {
    // goToPage, currentPage, inProgress, menuItems
    // constructor(props) {
    //     super(props);
    // }

    // showSettings(event) {
    //     event.preventDefault();
    // }

    render() {
        let styles = {};
        const items = this.props.menuItems.map((el, id) => <MobNavItem key={id} id={id} {...el} goToPage={this.props.goToPage} currentPage={this.props.currentPage} />);
        return (
            <div className={s.main}>
                <div className={s.bg}>
                    {/* <div className={s.bttnWrapper}>
                        <MobMarketplaceBttn title='Marketplace' url='http://' />
                    </div> */}

                </div>

                <Menu
                    right
                    styles={styles}
                    id={"mobmenu"}
                    className={s.menu}
                    burgerButtonClassName={s.burgerButton}
                    burgerBarClassName={s.burgerBar}
                    crossButtonClassName={s.crossButton}
                    crossClassName={s.cross}
                    menuClassName={s.menu}
                    morphShapeClassName={s.morphShape}
                    itemListClassName={s.itemList}
                    overlayClassName={s.overlay}
                    htmlClassName={s.html}
                    bodyClassName={s.body}
                    // customBurgerIcon={ <img src={ burgerIcon } /> }

                >
                    {items}
                </Menu>

                <div className={s.warning__bg}>
                    <div className={s.warning__text}>Mobile version under construction. Please visit web version</div>
                </div>
            </div>
        );
    }
}

// export default Mobnav;

const mapStateToProps = state => ({
  menuItems: state.menu.mob.items
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(Mobnav);