import React from 'react';
import s from './GLinkCont.module.scss';

const GLinkCont = ({ title, items }) => {
    const itemsArr = items.map(( el, id ) => {
        return (
            <div key={id} style={{ display: "flex", alignItems: "center", whiteSpace: "pro" , padding: "0.08em 0"}}>
                <img src={el.icon} alt={el.text} />
                <a href={el.url} style={{ padding: "0 0 0px 0.5em", color: "#5ec2ff" }}>{el.text}</a>
            </div>
        )
    });
    return (
        <div className={s.main} >
            <div className={s.content}>
                <div className={s.title}>{ title }</div>
                <div className={s.itemsCont}>{ itemsArr }</div>
            </div>
        </div>
    )
}

export default GLinkCont;