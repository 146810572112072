import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCount, incLoaded } from '../store/reducers/loaderProgress';

function preloadImage (src, loadCallback) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      setTimeout(() => { // для того что бы лоадер хоть на секунду задерживался если инет хороший
        if(!src.includes('data:image/png'))
          loadCallback()
        // console.log('resolve img: ', src);
        resolve(img)
      }, 1000)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
    window[src] = img
  })
}

export default function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    let isCancelled = false

    dispatch(setCount(imageList.length))

    async function effect() {
      if (isCancelled) {
        return
      }

      function loadCallback() {
        dispatch(incLoaded());
      }

      const imagesPromiseList = []
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i, loadCallback))
      }
  
      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [imageList, dispatch])

  return { imagesPreloaded }
}