import React, { useState } from 'react';
import s from './ContractAdressLine.module.scss';
import ReactTooltip from 'react-tooltip';
import copyBttnIcon from '../../../resources/icons/copy.png';

const ContractAdressLine = (props) => {
    const { kye, val } = props;
    const [isCopy, setIsCopy] = useState('Copy');
    const [randomID, setRandomID] = useState(String(Math.random()))

    return (
        <div className={s.main}>
            <div className={s.info__contracts_line}>
                <div className={s.info__contracts_key}> {kye} </div>
                <div className={s.info__contracts_val}>
                    {val}
                    <img data-tip={isCopy} data-for={randomID} src={copyBttnIcon} alt="" className={s.copyBttn} onClick={() => {
                        if (isSecureContext) {
                            navigator.clipboard.writeText(val);
                            setIsCopy('Copied!');
                        } else {
                            console.log('---> Copy is not available');
                            console.log('isSecureContext: ', isSecureContext);
                            console.log('--------------------------');
                        }
                    }} />
                    <ReactTooltip id={randomID} backgroundColor='rgba(0,0,0, 0.5)' isCapture place="right" type="info" effect="solid" getContent={[() => { return isCopy }, 1000]} />
                </div>

            </div>
        </div>

    )
}

export default ContractAdressLine;