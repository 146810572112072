//social link icons
// facebookIcon, discordIcon, twitterIcon, telegramIcon
import facebookIcon from '../resources/icons/social/fb.png';
import discordIcon from '../resources/icons/social/discord.png';
import twitterIcon from '../resources/icons/social/twitter.png';
import telegramIcon from '../resources/icons/social/telegram.png';
//end social link icons

// footer
// headPhoneIcon, emailIcon
import headPhoneIcon from '../resources/icons/headphones_icon.png';
import emailIcon from '../resources/icons/email_icon.png';
// end footer

// main page
import mainLogo from '../resources/icons/logo/logo.png';
// end main page

// nftItems
import nftItemsBird from '../resources/images/nft_items/bird.png';
// nftItemsIcon1, nftItemsIcon2, nftItemsIcon3
import nftItemsIcon1 from '../resources/images/nft_items/1.png';
import nftItemsIcon2 from '../resources/images/nft_items/2.png';
import nftItemsIcon3 from '../resources/images/nft_items/3.png';
// end nftItems

// coin
import coinPlug from '../resources/images/oasis_coin/item_backing.png';
import coinGlow from '../resources/images/glow.png';

// import fullCoinIcon1 from '../resources/images/oasis_coin/3.png';
// import fullCoinIcon2 from '../resources/images/oasis_coin/1.png';
// import fullCoinIcon3 from '../resources/images/oasis_coin/4.png';
// import fullCoinIcon4 from '../resources/images/oasis_coin/2.png';
// import fullCoinIcon5 from '../resources/images/oasis_coin/5.png';
// import fullCoinIcon6 from '../resources/images/oasis_coin/6.png';
// import fullCoinIcon7 from '../resources/images/oasis_coin/7.png';
// import fullCoinIcon8 from '../resources/images/oasis_coin/8.png';
// import fullCoinIcon9 from '../resources/images/oasis_coin/9.png';

import coinIcon1 from '../resources/images/oasis_coin/i3.png';
import coinIcon2 from '../resources/images/oasis_coin/i1.png';
import coinIcon3 from '../resources/images/oasis_coin/i4.png';
import coinIcon4 from '../resources/images/oasis_coin/i2.png';
import coinIcon5 from '../resources/images/oasis_coin/i5.png';
import coinIcon6 from '../resources/images/oasis_coin/i6.png';
import coinIcon7 from '../resources/images/oasis_coin/i7.png';
import coinIcon8 from '../resources/images/oasis_coin/i8.png';
import coinIcon9 from '../resources/images/oasis_coin/i9.png';

// mainCoinView, leftArrows, rightArrows
import mainCoinView from '../resources/images/oasis_coin/oasis_coin.png';
import leftArrows from '../resources/images/oasis_coin/oasis_coin_arrows_left.png';
import rightArrows from '../resources/images/oasis_coin/oasis_coin_arrows_right.png';
// end coin

// gem
// mainGemView, leftGemArrows, rightGemArrows, gemIcon1, gemIcon2, gemIcon3, gemIcon4, gemIcon5
import mainGemView from '../resources/images/oasis_gem/oasis_gem_coin.png';
import leftGemArrows from '../resources/images/oasis_gem/oasis_gem_arrows_left.png';
import rightGemArrows from '../resources/images/oasis_gem/oasis_gem_arrows_right.png';

// import fullGemIcon1 from '../resources/images/oasis_gem/1.png';
// import fullGemIcon2 from '../resources/images/oasis_gem/2.png';
// import fullGemIcon3 from '../resources/images/oasis_gem/3.png';
// import fullGemIcon4 from '../resources/images/oasis_gem/4.png';
// import fullGemIcon5 from '../resources/images/oasis_gem/5.png';

import gemIcon1 from '../resources/images/oasis_gem/i1.png';
import gemIcon2 from '../resources/images/oasis_gem/i2.png';
import gemIcon3 from '../resources/images/oasis_gem/i3.png';
import gemIcon4 from '../resources/images/oasis_gem/i4.png';
import gemIcon5 from '../resources/images/oasis_gem/i5.png';
// end gem

// team
import teamImg1 from '../resources/images/team/1.png';
import teamImg2 from '../resources/images/team/2.png';
import teamImg3 from '../resources/images/team/3.png';
import teamImg4 from '../resources/images/team/4.png';
import teamImg5 from '../resources/images/team/5.png';
import teamImg6 from '../resources/images/team/6.png';
import teamImg7 from '../resources/images/team/7.png';
import teamImg8 from '../resources/images/team/8.png';
import teamImg9 from '../resources/images/team/9.png';
// end team

// roadmap
import decor1 from '../resources/images/roadmap/moon.png';
import decor2 from '../resources/images/roadmap/pipes.png';
import decor3 from '../resources/images/roadmap/arrow_roadmap.png';
import decor4 from '../resources/images/blink.png';
// end roadmap

// pages bg
import mainPoster from '../resources/video/main.jpg';
import nftItemsBg from '../resources/images/nft_items/bg.jpg';
import gemBg from '../resources/images/oasis_gem/bg.jpg';
import coinBg from '../resources/images/oasis_coin/bg.jpg';
import teamBg from '../resources/images/team/bg.jpg';
import roamapBg from '../resources/images/roadmap/bg.jpg';
import footerBg from '../resources/images/footer/bg.jpg';
// mainPoster, nftItemsBg, gemBg, coinBg, teamBg, roamapBg, footerBg
// end pages bg

const initialState = {
    loaderProgress: {
        count:0,
        loaded:0
    },
    preloadImagesList: {
        desctop:[
            facebookIcon, discordIcon, twitterIcon, telegramIcon, // social icons
            headPhoneIcon, emailIcon,
            mainLogo,
            nftItemsBird, nftItemsIcon1, nftItemsIcon2, nftItemsIcon3,
            coinGlow,
            coinIcon1, coinIcon2, coinIcon3, coinIcon4, coinIcon5, coinIcon6, coinIcon7, coinIcon8, coinIcon9,
            mainCoinView, leftArrows, rightArrows,
            mainGemView, leftGemArrows, rightGemArrows, gemIcon1, gemIcon2, gemIcon3, gemIcon4, gemIcon5,
            teamImg1,
            decor1, decor2, decor3, decor4,
            mainPoster, nftItemsBg, gemBg, coinBg, teamBg, roamapBg, footerBg, // bg
        ],
        mob:[]
    },
    menu: {
        desctop: {
            items: [
                { title: "Home" },
                { title: "Video" },
                //{ title: "NFT items" },
                { title: "Oasis Gem" },
                { title: "Oasis Coin" },
                { title: "Team" },
                { title: 'Roadmap' },
                // { title: 'Partners' },
              ]
        },
        mob: {
            items: [
                { title: "Home" }
            ]
        }
    },
    socialLinksDatas :  [
        { name: 'facebook', url: 'https://www.facebook.com/LostOasisGame', icon: facebookIcon },
        { name: 'discord', url: 'https://discord.gg/6cttdxPE', icon: discordIcon },
        { name: 'twitter', url: 'https://twitter.com/lostoasisgame', icon: twitterIcon },
        { name: 'telegram', url: 'https://t.me/robolandgame', icon: telegramIcon },
    ],
    main: {
        bigLogo: mainLogo,
        poster: mainPoster,
        title: 'Lost Oasis',
        desc: 'Lost Oasis - blockchain game that combines high quality graphics, dynamic and addictive gameplay and the main advantages of Play to Earn games',
        bttn1: {
            title: "Join Whitelist",
            url: './comingsoon'
        },
        bttn2: {
            title: "Join Discord",
            url: 'https://discord.gg/6cttdxPE'
        },
        phrases: [
            'PLAY FREE!',
            'PLAY FUN!',
            'PLAY TO EARN!'
        ],
        community:{
            title:'community',
            count:'00 036 454'
        }
    },

    team: {
        title:'Team',
        desc:'The team always plays an important role in the success of a project. As a team, we have been working with each other for more than 5 years, with everyone being highly experienced in their own expertise',
        members: [
            { image: teamImg1, name: 'Nikolay Yatsishin', description: 'C0-FOUNDER/CEO', link:'https://google.com/', skills: ['Program Manager', '10 years of experience in Game Development', 'Good experience in bringing a product from idea to reality'] },
            { image: teamImg2, name: 'Denys Timoshenko', description: 'C0-FOUNDER/CTO', link:'https://google.com/', skills: ['CTO', 'Over 10 years of experience developing social and mobile games', 'Extensive technical knowledge'] },
            { image: teamImg3, name: 'Andrew Suprunov', description: 'CPO', link:'https://google.com/', skills: ['CPO at TruePartners', 'Strong IT and product background', 'Overall knowledge and experience in the startup building, communication with investment funds and VC, M&A'] },
            { image: teamImg4, name: 'Alex Sirota', description: 'Lead developer', link:'https://google.com/', skills: ['Quality software engineer', 'Over 7 years hands-on experience in software development'] },
            { image: teamImg5, name: 'Dmitry Tarasenko', description: 'Art-Lead', link:'https://google.com/', skills: ['Over 8 years EXP in gaming industry and animation.'] },
            { image: teamImg9, name: 'Vadim Kalashov', description: 'Writer', link:'https://google.com/', skills: ['Likes to be incognito','Author of a series of books','Writer of a TV series'] },
            { image: teamImg6, name: 'Carlos Rodriguez', description: 'Lead Community Manager', link:'https://google.com/', skills: ['Extensive experience of working with Spanish, English and Philippines crypto-communities'] },
            { image: teamImg7, name: 'Elvira Vinogradchaya', description: '2D Artist', link:'https://google.com/', skills: ['Loves to create amazing worlds'] },
            { image: teamImg8, name: 'Elizaveta Petrunenko', description: 'UI/UX Artist', link:'https://google.com/', skills: ['Advanced experience in creating UI/UX for mobile Farm games'] },

        ]
    },
    coin: {
        title: 'What`s oasis coin',
        desc: 'Apart from Oasis Coin, Oasis Gem is an exclusive currency that makes players become investors.',
        mainIconView: mainCoinView,
        glowIcon: coinGlow,
        blinkIcon: decor4,
        bttnText: 'Detail',
        content: {
            left:{
                title: 'EARN',
                arrowsImage: leftArrows,
                items: [
                    { title: 'Selling Spices', icon: coinIcon1, style:{margin:'0 -11.5em -5em 0'} },
                    { title: 'Tournaments', icon: coinIcon2, style:{margin:'0em 0 -5em 0em'} },
                    { title: 'Renting NFT', icon: coinIcon3, style:{margin:'0 -11.5em 0 0'} },
                    { title: 'Season PassES', icon: coinIcon4, style:{margin:'-5em 0 0 0'} },
                    { title: 'Special Events', icon: coinIcon5, style:{margin:'-5em -11.5em 0 0'} },
                ]
            },
            right: {
                title: 'UTILITIES',
                arrowsImage: rightArrows,
                items: [
                    { title: 'Exchange', icon: coinIcon6, style:{margin:'0.5em 0 0 0'} },
                    { title: 'Buying Spices', icon: coinIcon7, style:{margin:'-0.7em 0 0 0'} },
                    { title: 'Lost Boxes', icon: coinIcon8, style:{margin:'-0.7em 0 0 0'} },
                    { title: 'NFT Rental Fee', icon: coinIcon9, style:{margin:'-0.7em 0 0 0'} },
                ]
            }
        }
    },
    gem: {
        title: 'What’s Oasis GEM',
        desc: 'Apart from Oasis Coin, Oasis Gem is an exclusive currency that makes players become investors.',
        mainIconView: mainGemView,
        glowIcon: coinGlow,
        blinkIcon: decor4,
        bttnText: 'Detail',
        content: {
            left:{
                title: '',
                arrowsImage: leftGemArrows,
                items: [
                    { title: 'FREE TO EARN', icon: gemIcon1, style:{margin:'0 0 7.3em 0em'} },
                    { title: 'MARKET', icon: gemIcon2, style:{margin:'7.3em 0 0 0em'} },
                ]
            },
            right: {
                title: '',
                arrowsImage: rightGemArrows,
                items: [
                    { title: 'Evolve Game objects', icon: gemIcon3, style:{margin:'0 0 0.4em 0'} },
                    { title: 'Staking', icon: gemIcon4, style:{margin:'0 0 0 0'} },
                    { title: 'Lost Box', icon: gemIcon5, style:{margin:'1.3em 0 0 0'} },
                ]
            }
        }
    },
    nftitems: {
        part1: {
            title: 'Early Bird Mint',
            desc: 'A unique NFT available only to the early community',
            note: 'Only whitelisted users can be participating in this mint',
            item: {
                price: 10,
                lable: 'Price: ',
                currency: 'USD',
                icon: { view: nftItemsBird, style:{padding:'0 0 3em 0'} }
            },
            bttn: {
                lable: 'Join to whitelist',
                url: '/comingsoon'
            },
        },
        part2: {
            title: 'Elite Club',
            items: [
                { icon: { view: nftItemsIcon1, style:{padding:'0 0 3em 0'} } },
                { icon: { view: nftItemsIcon2, style:{padding:'0 0 3em 0'} } },
                { icon: { view: nftItemsIcon3, style:{padding:'0 0 3em 0'} } }
            ],
            note: 'Coming soon...',
            bttn: {
                lable: 'Details',
                url: '/comingsoon'
            }
        }
    },
    roadmap: {
        title: 'Roadmap',
        desc: '',
        decor1: decor1,
        decor2: decor2,
        decor3: decor3,
        decor4: decor4,
        pages: [
            {
                title: '2022',
                items: [
                    {
                        title: 'Q1 2022',
                        desc: 'Milestone 1',
                        list: [{step: 'Alpha test'}, {step: 'Website'}, {step: 'Promo video'}]
                    },
                    {
                        title: 'Q2 2022',
                        desc: 'Milestone 2',
                        list: [{step: 'Smart contracts'}, {step: 'Wallet connection'}, {step: 'Dapp/Marketplace'}, {step: 'First NFT sale'}]
                    },
                    {
                        title: 'Q3 2022',
                        desc: 'Milestone 3',
                        list: [{step: 'Beta test'}, {step: 'Land sale'}, {step: 'Second NFT sale'}, {step: 'Airdrop'}]
                    },
                    {
                        title: 'Q4 2022',
                        desc: 'Milestone 4',
                        decor: decor3,
                        text: '2023',
                        list: [{step: 'Official Launch'}, {step: 'Special events'}, {step: 'Land rental'}, {step: 'New locations'}]
                    }
                ]
            },
            {
                title: '2023',
                items: [
                    {
                        title: 'Q1 2023',
                        desc: 'Milestone 5',
                        list: [{step: 'Staking'}, {step: 'Tournaments & Leaderboards'}, {step: 'Social system'}, {step: 'New locations'}]
                    },
                    {
                        title: 'Q2 2023',
                        desc: 'Milestone 6',
                        list: [{step: 'Mini games'}, {step: 'Lotteries'}, {step: 'Unions system'}, {step: 'New locations'}]
                    },
                    {
                        title: 'Q3 2023',
                        desc: 'Milestone 7',
                        list: [{step: 'Mini games 2'}, {step: 'Seasonal Union Tournaments'}, {step: 'New locations'}]
                    },
                    {
                        title: 'Q4 2023',
                        desc: 'Milestone 8',
                        decor: decor3,
                        text: '...to the moon',
                        list: [{step: 'Mini games 3'}, {step: 'Seasonal Union Tournaments'}, {step: 'Cosmetic system'}, {step: 'New locations'}]
                    }
                ]
            }
        ]
    },
    comingsoon: {
        title: 'Coming soon',
        desc: 'We are still working on it',
        inputFieldPlaceholder: 'Your Email and Get Notified...' 
    },
    footer: {
        copyrightText: '2022 ALL RIGHT RESERVED',
        partnershipInquiry: {
            title: 'Partnership Inquiry:',
            items: [
                { 
                    title: 'Community/Business Partnership: ',
                    separator: '|',
                    linkItems: [
                        { text: 'Email Us', url: 'mailto://partnership@lostoasis.io' },
                        // { text: 'Sign Up', url: 'https://google.com/' }
                    ]
                },
                // { 
                //     title: 'Gaming Guild Application: ',
                //     separator: '|',
                //     linkItems: [
                //         { text: 'Email Us', url: 'https://google.com/' },
                //         { text: 'Sign Up', url: 'https://google.com/' }
                //     ]
                // },
                // { 
                //     title: 'Creator Programm: ',
                //     separator: '|',
                //     linkItems: [
                //         { text: 'Email Us', url: 'https://google.com/' },
                //         { text: 'Sign Up', url: 'https://google.com/' }
                //     ]
                // }
            ]
        },
        gameSupport: {
            title: 'Game Support Inquiry:',
            items: [
                // { icon: headPhoneIcon, text: 'Lost Oasis Helpshift', url: 'https://google.com' },
                // { icon: headPhoneIcon, text: 'Lost Oasis Helpshift', url: 'https://google.com' },
                // { icon: headPhoneIcon, text: 'Lost Oasis Helpshift', url: 'https://google.com' },
                { icon: emailIcon, text: 'Lost Oasis Help', url: 'mailto://support@lostoasis.io' }
            ]
        },
    },
    contractAdressess: [
        {
            title: "$OGM",
            items: [
            {
                id: 1,
                kye: "ERC1155",
                val: "TO BE ANNOUNCED",
                // val: "TO BE ANNOUNCED",
            },
            // {id:1, kye:'2BEP20', val: '0X9FD87AEFE02441B123C3C32466CD9DB4C578618F'},
            ],
        },
        {
            title: "$OCN",
            items: [
            {
                id: 1,
                kye: "ERC1155",
                val: "TO BE ANNOUNCED",
                // val: "TO BE ANNOUNCED",
            },
            ],
        },
    ],
};

export default initialState;