import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './Roadmap.module.scss';
// import RoadmapItem from './roadmapItem/RoadmapItem';
import RoadmapPage from './roadmapPage/RoadmapPage';
import { motion } from "framer-motion";

import styled from 'styled-components';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const StyledSlider = styled(Slider)`
    li.slick-active {
        display: flex;
        justify-content: center;
        align-items: center;

        background: rgba(95, 95, 95, 0.1);
        border: 2px solid #fff;
        border-radius: 0.25em;
    }
`;

const Roadmap = ({id}) => {
    const [isRocket, setIsRocket] = useState(false);
    const data = useSelector(store => store.roadmap);
    const pages = data.pages.map((el, id) => <RoadmapPage key={id} {...el} />);

    const slider_settings = {
        arrows: false,
        dots: true,
        // dotsClass: "cdots",
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,

        beforeChange: (prev, next) => {
            setIsRocket(Boolean(next));
        },

        appendDots: dots => <ul className={s.dotsCont} style={{display: "flex", justifyContent:"center", listStyleType:"none"}}>{dots}</ul>,
        customPaging: function (i) {
            return (
                <div className={s.ftSlickDotsCustom}>{ data.pages[i].title }</div>
            );
        },
        dotsClass: s.slickDots
    };

    return (
        <div className={s.main} id={id} >

            <div className={s.title}>{ data.title }</div>
            <div className={s.description}>{ data.desc }</div>
            
            <img className={s.decor1} src={data.decor1} alt="" />
            {isRocket && (
                <motion.div
                initial="hidden"
                whileInView="visible"
                // viewport={{ once: true }}
                transition={{ type: 'spring', delay: 0.2, duration: 1.5 }}
                variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                }}>
                    <img className={s.decor2} src={data.decor2} alt="" />
                    {/* <div className={s.tobeText}>...to the moon</div> */}
                </motion.div>
            )}
             {!isRocket && (
                <motion.div
                initial="hidden"
                whileInView="visible"
                // viewport={{ once: true }}
                transition={{ type: 'spring', delay: 0.2, duration: 2 }}
                variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                }}>
                    {/* <div className={s.tobeText + ' ' + s.text1}>2023</div> */}
                </motion.div>
            )}
            {/* <img className={s.decor3} src={data.decor3} alt="" /> */}
            {/* <img className={s.decor4} src={data.decor4} alt="" /> */}

            <StyledSlider {...slider_settings} className={s.slider}>
                { pages }
            </StyledSlider>

        </div>
    )
}

export default Roadmap;