import React from 'react';
import s from './MobMain.module.scss';
import ContractAdressPanel from '../panels/contractAdressPanel/ContractAdressPanel';

// import heroImg from '../../resources/images/main/mob_bg2.jpg';
import logoImg from '../../resources/icons/logo/logo.png';

// import desktopVideo from '../../../resources/video/main.mp4';
// import tabletVideo from '../../../resources/video/main/main_1024.mp4';
// import largMobileVideo from '../../../resources/video/main/main_768.mp4';
// import mobileVideo from '../../../resources/video/main/main_480.mp4';
// import smallMobileVideo from '../../../resources/video/main/main_320.mp4';
import { useSelector } from 'react-redux';

// const getVideoSrc = width => {
//     if (width >= 1024) return desktopVideo;
//     if (width >= 768) return tabletVideo;
//     if (width >= 480) return largMobileVideo;
//     if (width >= 320) return mobileVideo;
//     return smallMobileVideo;
// };


const MobMain = ({id}) => {
    const mainData = useSelector(store => store.main);
    const title = mainData.title;
    const desc = mainData.desc;
    const hashtags = mainData.hashtags;

    const communityTitle = mainData.community.title;
    const communityCount = mainData.community.count;

    // const contractAdressess = useSelector(store => store.contractAdressess);
    // const panels = contractAdressess.map((el, id) => <div key={id} style={{ margin: '2em 0' }}><ContactsList key={id} {...el} /></div>);

    // const videosrc = getVideoSrc(window.innerWidth);
    return (
        <div className={s.main} id={id} >

            <div className={s.info}>
                
                {/* <div className={s.info__title}>{ title }</div> */}
                <img src={logoImg} alt="" className={s.logoImg} />

                <div className={s.info__description}>
                    { desc }
                    <div className={s.info__description_tags}>{ hashtags }</div>
                </div>
                <div className={s.info__totalplayers}>
                    <div className={s.info__totalplayers_title}>{ communityTitle }</div>
                    <div className={s.info__totalplayers_count}>{ communityCount }</div>
                </div>

                <ContractAdressPanel />

                {/* <div className={s.tradeNowBttn}>
                    <div className={s.traderNowbBttnTitle}>TRADE NOW</div>
                </div> */}

                {/* <img src={heroImg} alt="" className={s.heroImg} /> */}

                {/* <video className='videoTag' autoPlay loop muted>
                    <source src={videosrc} type='video/mp4' />
                </video> */}

            </div>
        </div>
    )
}

export default MobMain;