import React from 'react';
// import { useSelector } from 'react-redux';
import s from './ProgressBar.module.scss';

const ProgressBar = ({ progress }) => {
    // const data = useSelector(store => store.nftitems);
    
    return (
        <div className={s.main} >
            <div className={s.progress__line}>
                <div className={s.progress__bg}><div className={s.progress__bar} style={{width: progress + '%'}}></div></div>
            </div>
        </div>
    )
}

export default ProgressBar;