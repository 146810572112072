import React from 'react';
import s from './NavItem.module.scss';

const NavItem = (props) => {
    const { id, title, goToPage, currentPage } = props;

    const onClick = (id, callback) => {
        callback(id);
    }

    return (
        <div className={s.main} >
            <div className={s.nav__item}>
                <div className={`${s.navlink} ${(currentPage === id) ? s.navLinkActive : s.navLinkNActive}`} onClick={e => onClick(id, goToPage)} > {title} </div>
            </div>
        </div>
    )
}

export default NavItem;