import React, { useEffect, useRef } from 'react';
import s from './MobNavItem.module.scss';
// import { getWindowDimensions } from '../../utils/utils';

const MobNavItem = (props) => {
    const { id, title, goToPage } = props;
    const navItemRef = useRef(null);

    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
    });

    let cur = false;

    const onClick = () => {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
          });
    }

    return (
        <div className={s.main} >
            <div className={s.nav__item}>
                <div ref={navItemRef} className={`${s.navlink} ${cur ? s.navLinkActive : s.navLinkNActive}`} onClick={e => onClick()} > {title} </div>
            </div>
        </div>
    )
}

export default MobNavItem;