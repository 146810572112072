import React from 'react';
import { useSelector } from 'react-redux';
import s from './Coin.module.scss';
import CoinContent from './coinContent/CoinContent';

const Coin = ({id}) => {
    const data = useSelector(store => store.coin);
    return (
        <div className={s.main} id={id} >

            <div className={s.title}>{ data.title }</div>
            <div className={s.description}>{ data.desc }</div>
            
            <CoinContent />

        </div>
    )
}

export default Coin;