import React from "react";
import s from './Input.module.scss';

const Input = ({
    input,
    label,
    type,
    placeholder,
    meta: { touched, error, warning }
  }) => {

    return (
        <div className={ s.main }>
            <label htmlFor="email" className={ s.email__lable } > { label } </label>
            <input
                {...input}
                placeholder={ placeholder }
                type={ type }
                className={ s.email__field + " " + (touched && ((error ? s.error_input : "") || (warning ? s.warning_input : ""))) }
            />
            {touched &&
                ((error && <span className={ s.error }>{ error }</span>) ||
                    (warning && <span className={ s.warning }>{ warning }</span>))}
        </div>
    );
}

export default Input;