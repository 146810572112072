import React from 'react';
// import LeftPart from '../leftParth copy/LeftPart';
import s from './FeatureItem.module.scss';
import { motion } from "framer-motion";

const LeftItem = ({ title, icon }) => {
    return (
        <div className={`${s.content} ${s.left}`}>
            <div className={`${s.title} ${s.left}`}>{title}</div>
            <img src={icon} alt={title} className={`${s.icon} ${s.left}`} />
        </div>
    );
}

const RightItem = ({ title, icon, id }) => {
    return (
        <div className={`${s.content} ${s.right}`}>
            <div className={s.itemCont}>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    // viewport={{ once: true }}
                    // transition={{ type: 'spring', delay: 0.2, duration: 1.5 }}
                    variants={{
                        visible: { scale: 1, rotation: 0.02, transition:{ type: 'tween', delay: 0.1*id, duration: 0.4, ease: "linear" } },
                        hidden: { scale: 0, rotation: 0.02, transition:{ type: 'tween', delay: 0, duration: 0, ease: "linear" } }
                    }}>

                        <img src={icon} alt={title} className={`${s.icon} ${s.right}`} />

                    </motion.div>
            </div>
            <div className={`${s.title} ${s.right}`}>{title}</div>
        </div>
    );
}

const FeatureItem = ({ title, icon, position, style, id }) => {

    let isRitght = false;
    if (position === 'right') {
        isRitght = true;
    }

    return (
        <div className={s.main} style={style}>
            {isRitght && <RightItem title={title} icon={icon} id={id} />}
            {!isRitght && <RightItem title={title} icon={icon} id={id} />}
        </div>

    );

}

export default FeatureItem;