import React from "react";
import s from './RoadmapItem.module.scss';

import pointer from '../../../resources/images/roadmap/point_roadmap.png';
import point from '../../../resources/images/roadmap/point2_roadmap.png';
import star from '../../../resources/images/blink.png';

import CardItem from "./cardItem/CardItem";
import { motion } from "framer-motion";

const RoadmapItem = ({ title, desc, list, style, pos, text='', decor = '' }) => {

    const duration = 2;

    const speedMultiplier = 0.18;
    const itemDelay =  (0.2 + (0.2 * pos)) * speedMultiplier;

    const pointDuration = 2;
    const pointDelay =  (1.8 * pos) * speedMultiplier;
    const pointerSpeed = 0.5 * speedMultiplier;

    const isOnce = false;
    const type = "spring";//"tween";

    const steps = list.map((el, id) => <CardItem key={id} {...el} id={id} type={type} pointDuration={pointDuration} pointDelay={pointDelay} pointerSpeed={pointerSpeed} />);

    return (
        <div className={s.main}>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: isOnce }}
                // transition={{ type: type, delay: itemDelay, duration: duration }}
                variants={{
                    visible: { opacity: 1, rotate: 0.02, transition:{ type: type, delay: (pointerSpeed * 1) + pointDelay-0.15, duration: duration } },
                    hidden: { opacity: 0, rotate: 0.02, transition:{ type: type, delay: (pointerSpeed * 1) + pointDelay-0.15, duration: 0 } }
                }}>
                    
                    <div className={s.content} style={style}>
                        <div className={s.title}>{ title }</div>
                        <div className={s.desc}>{ desc }</div>

                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: isOnce }}
                        // transition={{ type: type, delay: (pointerSpeed * 1) + pointDelay, duration: pointDuration }}
                        variants={{
                            visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 1) + pointDelay, duration: pointDuration } },
                            hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 1) + pointDelay, duration: 0 } }
                        }}><img className={s.decor2} src={point} alt="" /></motion.div>

                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: isOnce }}
                        // transition={{ type: type, delay: (pointerSpeed * 2) + pointDelay, duration: pointDuration }}
                        variants={{
                            visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 2) + pointDelay, duration: pointDuration } },
                            hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 2) + pointDelay, duration: 0 } }
                        }}><img className={s.decor3} src={point} alt="" /></motion.div>

                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: isOnce }}
                        // transition={{ type: type, delay: (pointerSpeed * 3) + pointDelay, duration: pointDuration }}
                        variants={{
                            visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 3) + pointDelay, duration: pointDuration } },
                            hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 3) + pointDelay, duration: 0 } }
                        }}><img className={s.decor4} src={point} alt="" /></motion.div>

                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: isOnce }}
                        // transition={{ type: type, delay: (pointerSpeed * 4) + pointDelay, duration: pointDuration }}
                        variants={{
                            visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 4) + pointDelay, duration: pointDuration } },
                            hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 4) + pointDelay, duration: 0 } }
                        }}><img className={s.decor5} src={point} alt="" /></motion.div>

                        <div className={s.decor1Cont}>
                            <motion.div
                            layout
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: isOnce }}
                            // transition={{ type: type, delay: (pointerSpeed * 5) + pointDelay, duration: pointDuration }}
                            variants={{
                                visible: { scale: 1, transition: { type: type, delay: (pointerSpeed * 5) + pointDelay, duration: pointDuration } },
                                hidden: { scale: 0, transition: { type: type, delay: (pointerSpeed * 5) + pointDelay, duration: 0 } }
                            }}><img src={pointer} className={s.decor1} alt="" /></motion.div>
                        </div>

                        <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: isOnce }}
                        // transition={{ type: type, delay: (pointerSpeed * 6) + pointDelay, duration: pointDuration }}
                        variants={{
                            visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 6) + pointDelay, duration: pointDuration } },
                            hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 6) + pointDelay, duration: 0 } }
                        }}><img className={`${s.decor6} ${s['a'+pos]}`} src={star} alt="" /></motion.div>
                        
                        
                        
                        <div className={s.card}>
                            { steps }
                        </div>

                        {decor && (
                            <>
                                <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: isOnce }}
                                // transition={{ type: type, delay: (pointerSpeed * 7) + pointDelay, duration: pointDuration }}
                                variants={{
                                    visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 7) + pointDelay, duration: pointDuration } },
                                    hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 7) + pointDelay, duration: 0 } }
                                }}>

                                    <img className={s.decor} src={decor} alt="" />

                                </motion.div>

                                <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: isOnce }}
                                // transition={{ type: type, delay: (pointerSpeed * 7) + pointDelay, duration: pointDuration }}
                                variants={{
                                    visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 8) + pointDelay, duration: pointDuration } },
                                    hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 8) + pointDelay, duration: 0 } }
                                }}>

                                    <div className={s.tobeText}>{ text }</div>

                                </motion.div>

                                <motion.div
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: isOnce }}
                                // transition={{ type: type, delay: (pointerSpeed * 6) + pointDelay, duration: pointDuration }}
                                variants={{
                                    visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * 6) + pointDelay, duration: pointDuration } },
                                    hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * 6) + pointDelay, duration: 0 } }
                                }}><img className={`${s.decor99} ${s['a'+pos]}`} src={star} alt="" /></motion.div>

                            </>
                        )}
                    </div>

                </motion.div>
        </div>
    )
}

export default RoadmapItem;