import React from 'react';
import s from './MarketplaceBttn.module.scss';
import { Link } from 'react-router-dom';

const MarketplaceBttn = ({ title, re = 'main', url='/' }) => {
    const onClick = () => {
        let plugWindow = document.getElementById("marketplace");
        if (plugWindow && plugWindow.style) {
            if( window.getComputedStyle( plugWindow ).display === "none" ) {
                plugWindow.style.display = "flex";
            } else {
                plugWindow.style.display = "";
            }
        }
    }
    return (
        <div className={s.main}>
            {/* marketplace/${re} */}
            <Link className={s.link} to={url}>
                <div className={s.bttnWrap}>
                    <button onClick={onClick.bind(this)} className={s.bttn}>
                        <span className={s.title}>{title}</span>
                    </button>
                </div>
            </Link>
        </div>
    );
}

export default MarketplaceBttn;