import React from 'react';
import s from './Main.module.scss';
import asample from '../../resources/video/main.mp4';
import { motion } from "framer-motion";
import ContactAdressGorizontalList from '../items/contactAdressGorizontalList/ContactAdressGorizontalList';
import { useSelector } from 'react-redux';
import MBttn from '../mBttn/MBttn';
import bttnDIcon from '../../resources/icons/social/discord_icon_for_button.png';
import { InView } from 'react-intersection-observer';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const Main = () => {
    const videoRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {

        // console.log(videoRef.current);

        // let isPlaying = videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended 
        //         && videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

        // let isPlaying = !videoRef.current.paused;

        if (isVisible) {

            // const playPromise = videoRef.current.play();
            // if (playPromise !== null){
            //     playPromise.catch(() => { videoRef.current.play(); })
            // }

            videoRef.current.play().then(() => {
                videoRef.current.play();
            })

        } else {

            videoRef.current.pause();
            videoRef.current.currentTime = 0;

        }

    }, [isVisible]);

    const mainData = useSelector(store => store.main);
    const title = mainData.title;
    const logo = mainData.bigLogo;
    const desc = mainData.desc;
    const hashtags = mainData.hashtags;
    const phrasesArr = mainData.phrases;
    const poster = mainData.poster;

    const communityTitle = mainData.community.title;
    const communityCount = mainData.community.count;

    const contractAdressess = useSelector(store => store.contractAdressess);
    const panels = contractAdressess.map((el, id) => <div key={id} style={{ margin: '0.6em 0' }}><ContactAdressGorizontalList key={id} {...el} /></div>);
    const phrases = phrasesArr.map((el, id) => <div key={id} className={s.blablaItem}>{el}</div>);
    return (
        <div className={s.main}>
            <div className={s.info}>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    // viewport={{ once: true }}
                    transition={{ type: 'spring', delay: 0.2, duration: 1 }}
                    variants={{
                        visible: { x: 0, opacity: 1 },
                        hidden: { x: -30, opacity: 0 }
                    }}>
                    {/* <div className={s.info__title}>{ title }</div> */}
                    <img className={s.logoIcon} src={logo} alt={title} />
                </motion.div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    // viewport={{ once: true }}
                    transition={{ type: 'spring', delay: 0.4, duration: 1 }}
                    variants={{
                        visible: { y: 0, opacity: 1 },
                        hidden: { y: 30, opacity: 0 }
                    }}>

                    <div className={s.info__description}>
                        {desc}
                        <div className={s.info__description_tags}>{hashtags}</div>
                    </div>

                    <div className={s.bttns}>
                        <MBttn title='Join Whitelist' url='/comingsoon' style={{ width: '11em' }} />
                        <MBttn title='Join Discord' url='https://discord.gg/6cttdxPE' external={true} icon={bttnDIcon} style={{ width: '11em' }} />
                    </div>

                    <div className={s.info__totalplayers}>
                        <div className={s.info__totalplayers_title}>{communityTitle}</div>
                        <div className={s.info__totalplayers_count}>{communityCount}</div>
                    </div>

                    <div className={s.blablaBlock}>
                        {phrases}
                    </div>
                    {/* <div className={s.info__contracts}>
                        {panels}
                    </div> */}

                </motion.div>

            </div>
            {/* controls */}

            <InView threshold='1' onChange={(inView, entry) => setIsVisible(inView)}>
                <video ref={videoRef} className={s.videoTag} poster={poster} loop={true} muted playsInline preload='auto' controlsList="nodownload">
                    <source src={asample} type='video/mp4' />
                </video>
            </InView>

        </div>
    )
}

export default Main;