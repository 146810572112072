import React, { useState } from "react";
import s from "./SubscribeForm.module.scss";
import { Form, Field } from "react-final-form";
import Input from "./input/Input";
import {
  email,
  ethereumAddress,
  required,
} from "../../utils/validators/validators";
import { subscribeAPI } from "../../../api/api";
import { useSelector } from 'react-redux';

const SubscribeForm = () => {
  const data = useSelector(store => store.comingsoon);

  const [subscribeInProgress, setSubscribeInProgress] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState(false);

  if (subscribed) {
    return <div className={s.success__message}>Data added successfully!</div>;
  } else if (error) {
    return <div className={s.error__message}>Error. Try again later</div>;
  }

  return (
    <Form
      onSubmit={(values) => {
        setSubscribeInProgress(true);
        subscribeAPI
          .subscribeMint(values.email)
          .then((resp) => {
            if (resp.result) {
              if (resp.hasOwnProperty("msg") && resp.msg === "Has entry") {
                // has entry
              } else {
                // done
              }
              setSubscribed(true);
            } else {
              setError(true);
              setTimeout(function () {
                setError(false);
                setSubscribeInProgress(false);
              }, 3000);
            }
          });
      }}
      validate={(values) => {
        const errors = {};
        let reqRes = required(values.email);
        if (reqRes) {
          errors.email = reqRes;
        }
        let checkEmailRes = email(values.email);
        if (checkEmailRes) {
          errors.email = checkEmailRes;
        }
        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit} className={s.form}>
          <div className={s.email}>
            <Field
              name="email"
              // type="email"
              placeholder={data.inputFieldPlaceholder}
              className={s.email__field}
              component={Input}
            />
          </div>
          <button className={s.submit__bttn} disabled={subscribeInProgress}></button>
        </form>
      )}
    </Form>
  );
};

export default SubscribeForm;
