import React from 'react';
import { useSelector } from 'react-redux';
import s from './Gem.module.scss';
import GemContent from './gemContent/GemContent';

const Gem = ({id}) => {
    const data = useSelector(store => store.gem);
    return (
        <div className={s.main} id={id} >

            <div className={s.title}>{ data.title }</div>
            <div className={s.description}>{ data.desc }</div>
            
            <GemContent />

        </div>
    )
}

export default Gem;