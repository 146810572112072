import React from 'react';
import s from './HorizontalSocialLinkItem.module.scss';

const HorizontalSocialLinkItem = ({ url, icon, name }) => {
    return (
        <div className={s.main}>
            <a href={url} className={s.social__link} target="_blank" rel="noreferrer">
                <img src={icon} alt={name} />
            </a>
        </div>
    )
}

export default HorizontalSocialLinkItem;