import React from 'react';
import s from './MBttn.module.scss';
import { Link } from 'react-router-dom';

const MBttn = ({ title, re = 'main', icon = '', style = {}, url = '/', external = false }) => {
    const onClick = () => {
        let plugWindow = document.getElementById("marketplace");
        if (plugWindow && plugWindow.style) {
            if (window.getComputedStyle(plugWindow).display === "none") {
                plugWindow.style.display = "flex";
            } else {
                plugWindow.style.display = "";
            }
        }
    }

    if (external == true) {
        return (
            <div className={s.main}>
                <a target="_blank" className={s.link} href={url}>
                    <div className={s.bttnWrap}>
                        <button onClick={onClick.bind(this)} className={s.bttn} style={style}>
                            {icon && <img className={s.icon} src={icon} alt="" style={{ width: "1.6em" }} />}
                            <span className={s.title}>{title}</span>
                        </button>
                    </div>
                </a>
            </div>
        );
    } else {
        return (
            <div className={s.main}>
                {/* marketplace/${re} */}
                <Link className={s.link} to={url}>
                    <div className={s.bttnWrap}>
                        <button onClick={onClick.bind(this)} className={s.bttn} style={style}>
                            {icon && <img className={s.icon} src={icon} alt="" style={{ width: "1.6em" }} />}
                            <span className={s.title}>{title}</span>
                        </button>
                    </div>
                </Link>
            </div>
        );
    }


}

export default MBttn;