import { combineReducers, createStore } from 'redux';
import initialState from './initialState';
import socialLinksDatas from './reducers/socialLinksDatas';
import menu from './reducers/menu';
import team from './reducers/team';
import contractAdressess from './reducers/contractAdressess';
import main from './reducers/main';
import footer from './reducers/footer';
import coin from './reducers/coin';
import gem from './reducers/gem';
import nftitems from './reducers/nftitems';
import roadmap from './reducers/roadmap';
import comingsoon from './reducers/comingsoon';
import loaderProgress from './reducers/loaderProgress';

import preloadImagesList from './reducers/preloadImagesList';

let reducers = combineReducers({
    menu,
    socialLinksDatas,
    coin,
    gem,
    team,
    contractAdressess,
    main,
    nftitems,
    roadmap,
    preloadImagesList,
    footer,
    comingsoon,
    loaderProgress
});

const store = createStore(
    reducers,
    initialState,
    window.devToolsExtension ? window.devToolsExtension : f=>f
);

window.store = store;

export default store;