import React from 'react';
// import NavItem from '../../nav/navItem/NavItem';
import FooterNavItem from './footerNavItem/FooterNavItem';
import s from './FooterNavPanel.module.scss';

const FooterNavPanel = ({ goToPage, currentPage, menuItems }) => {

    let itemsInColumn = 3;
    const chunkArray = (array, chunk) => {
        const newArray = [];
        for (let i = 0, length = array.length; i < length; i += chunk) {
            newArray.push(array.slice(i, i + chunk));
        }
        return newArray;
    }

    const columnsData = chunkArray(menuItems, itemsInColumn);
    const columns = columnsData.map((arr, i) => {
        const items = arr.map((el, k) => {
            let id = k + (i * itemsInColumn);
            return (
                <FooterNavItem key={k} id={id} {...el} goToPage={goToPage} currentPage={currentPage} />
            );
        });
        return (
            <div key={i} style={{
                display: 'flex',
                flexDirection:'column'
            }}>
                {items}
            </div>
        );
    });

    return (
        <div className={s.main} >
            {columns}
        </div>
    )
}

export default FooterNavPanel;