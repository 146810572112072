import React from 'react';
import s from './PLinkCont.module.scss';
import PLinkItem from './pLinkItem/PLinkItem';

const PLinkCont = ({ title, items }) => {
    const itemsArr = items.map(( el, id ) => <PLinkItem key={id} {...el} />);
    return (
        <div className={s.main} >
            <div className={s.content}>
                <div className={s.title}>{ title }</div>
                <div className={s.itemsCont}>{ itemsArr }</div>
            </div>
        </div>
    )
}

export default PLinkCont;