import React from 'react';
import s from './NLeftPart.module.scss';

const NLeftPart = (props) => {
    let isRight = false;
    if(props.position === 'right')
        isRight = true;
    
    return (
        <div className = { isRight ? s.main + ' ' + s.right : s.main + ' ' + s.left}>
            { !isRight && <div className={ isRight ? s.title + ' ' + s.right : s.title + ' ' + s.left}>{props.title}</div>}
            <div className={ isRight ? s.info + ' ' + s.right : s.info + ' ' + s.left}>
                {props.children}
            </div>
            { isRight && <div className={ isRight ? s.title + ' ' + s.right : s.title + ' ' + s.left}>{props.title}</div> }
        </div>
    )

}

export default NLeftPart;