import React from 'react';
import { motion } from "framer-motion"
import s from './SocialLinksPanel.module.scss';
import { useSelector } from 'react-redux';

const SocialLinksPanel = ({ type }) => {
    
    // const type = 'desctop';
    const itemsData = useSelector(store => store.socialLinksDatas);
    const items = itemsData.map((el, index) => {
        return (
            <a key={index} href={el.url} className="social__link" target="_blank">
                <img src={el.icon} alt={el.name} />
            </a>
        );
    });

    const styles = {
        desctop: {},
        mob: { top: '50%' }
    };
    let style = styles[type];

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ type: 'spring', delay: 0.5, duration: 1.0 }}
            variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 30, opacity: 0 }
            }} className={s.panel} style={style}>

            {items}

        </motion.div>
    )
}

export default SocialLinksPanel;