import React from "react";
import ReactPageScroller from "react-page-scroller";
import Intro from "../intro/Intro";
import Main from "../main/Main";
import ContractAdressPanel from "../panels/contractAdressPanel/ContractAdressPanel";
import Nav from "../nav/Nav";
import Mobnav from "../nav/Mobnav";
import Footer from "../footer/Footer";
import Coin from "../coin/Coin";
import Gem from "../gem/Gem";
import Team from "../team/Team";
import Roadmap from "../roadmap/Roadmap";
import NftItems from "../nftItems/NftItems";
import { connect } from "react-redux";
import MobMain from "../main/MobMain";
import { withRouterComp } from "../../hocs/withRouter";
import { compose } from "redux";
import SocialLinksPanel from "../panels/socialLinksPanel/SocialLinksPanel";

class Scroller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      inProgress: 0,
      blockScroll: false,
      isFooterVisble: false,
      isContractAdressPanelVisible: false,
      isTopMenuVisible: false,
      showFullMenu: window.matchMedia("(min-width: 1024px)").matches,
      isDesctopView: window.matchMedia("(min-width: 1024px)").matches,
    };
    this.goToCustomPage = this.goToCustomPage.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.isDesctopViewHandler = this.isDesctopViewHandler.bind(this);
    this.showFullMenuHandler = this.showFullMenuHandler.bind(this);
  }

  componentDidMount() {
    window.matchMedia("(min-width: 1024px)").addEventListener("change", this.showFullMenuHandler);
    window.matchMedia("(min-width: 1024px)").addEventListener("change", this.isDesctopViewHandler);
    window.addEventListener('keydown', this.onKeyPress);

    if(this.props.router.location && this.props.router.location.state && this.props.router.location.state.goTo) {
      let goTo = parseInt(this.props.router.location.state.goTo);
      setTimeout(()=>{ this.goToCustomPage(goTo) }, 100);
    }
  }

  componentWillUnmount() {
    window.matchMedia("(min-width: 1024px)").removeEventListener("change", this.showFullMenuHandler);
    window.matchMedia("(min-width: 1024px)").removeEventListener("change", this.isDesctopViewHandler);
    window.removeEventListener('keydown', this.onKeyPress);
  }

  showFullMenuHandler = (e) => this.setState({ showFullMenu: e.matches });
  isDesctopViewHandler = (e) => this.setState({ isDesctopView: e.matches });

  onKeyPress = (e) => {
    switch (e.key) {
      case 'Home':
        this.goToCustomPage(0);
        break;
      case 'End':
        let lastPageNum = this.props.menuItems.length;
        this.goToCustomPage(lastPageNum);
        break;
      case 'PageUp':
        let prevPageNum = 0;
        if(this.state.currentPage > 0)
        prevPageNum = this.state.currentPage - 1;

        this.goToCustomPage(prevPageNum);
        break;
      case 'PageDown':
        let nextPageNum = this.props.menuItems.length;
        if(this.state.currentPage < this.props.menuItems.length)
          nextPageNum = this.state.currentPage + 1;

        this.goToCustomPage(nextPageNum);
        break;
    
      default:
        // console.log(e.key);
        break;
    }
  }

  goToCustomPage = (eventKey) => {
    if (eventKey !== null) this.setState({ currentPage: eventKey }); 
  };

  pageOnChange = (number) => {
    const timeout = setTimeout(() => {
      this.setState({ blockScroll: false });
      clearTimeout(timeout);
    }, 550);

    this.setState({
      currentPage: number,
      isFooterVisble: number === 1,
      // isContractAdressPanelVisible: number >= 1,
      isContractAdressPanelVisible: number === 0 || number > 1,
      isTopMenuVisible: number === 0 || this.state.currentPage > number,
    });
  };

  onBeforePageScroll = (index) => {
    this.setState({
      blockScroll: true,
      inProgress: index,
    });

    // let caPanel = null;
    // if (index !== null && !index)
    //   caPanel = document.getElementById("contractAdressPanel");
    // if (caPanel && caPanel.style) caPanel.style.display = "none";

    // let fPanel = null;
    // if (index !== null && index !== 10)
    //   fPanel = document.getElementById("footer");
    // if (fPanel && fPanel.style) fPanel.style.display = "none";
  };

  render() {
    return (
      <>
        <SocialLinksPanel type={'desctop'} />

        {!this.state.showFullMenu && (
          <Mobnav
            goToPage={this.goToCustomPage}
            currentPage={this.state.currentPage}
            inProgress={this.state.inProgress}
          />
        )}
        {this.state.showFullMenu && (
          <Nav
            goToPage={this.goToCustomPage}
            currentPage={this.state.currentPage}
            inProgress={this.state.inProgress}
          />
        )}

        {this.state.isDesctopView &&
          this.state.isContractAdressPanelVisible && (
            <ContractAdressPanel />
          )}

        {/* scroller start */}
        {this.state.isDesctopView && (
          <>
            <ReactPageScroller
              blockScrollUp={this.state.blockScroll}
              blockScrollDown={this.state.blockScroll}
              goToPage={this.goToPage}
              animationTimer={400}
              onBeforePageScroll={this.onBeforePageScroll}
              pageOnChange={this.pageOnChange}
              customPageNumber={this.state.currentPage}
              // transitionTimingFunction={"linear"}
              renderAllPagesOnFirstRender={false}
              animationTimerBuffer={1}
            >
              
              <Main   id={0} />
              <Intro         />
              {/* <NftItems      /> */}
              <Gem           />
              <Coin          />
              <Team          />
              <Roadmap       />
              <Footer
                goToPage={this.goToCustomPage}
                currentPage={this.state.currentPage}
                inProgress={this.state.inProgress}
                socialLinksData={this.props.socialLinkData}
              />

            </ReactPageScroller>

            {/* {this.state.isFooterVisble && ()} */}
          </>
        )}
        {!this.state.isDesctopView && (
          <div
            style={{
              overflow: "auto",
            }}
          >
            {/* https://stackoverflow.com/questions/65114785/fancybox-in-react */}

            <MobMain      id={0}/>
            {/* <MobIntro           /> */}
          </div>
        )}
        {/* scroller end */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  menuItems: state.menu.desctop.items
});
const mapDispatchToProps = () => ({});

export default compose(
  withRouterComp,
  connect(mapStateToProps, mapDispatchToProps)
)(Scroller);