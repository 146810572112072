import React from 'react';
import { useSelector } from 'react-redux';
import s from './Team.module.scss';
import TeamItem from './teamItem/TeamItem';

import styled from 'styled-components';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const StyledSlider = styled(Slider)`
        
        .slick-dots li button:before {
            content: "";
            // position: absolute;
            // top: 3px;
            // left: 3px;
            // right: 3px;
            // bottom: 3px;
            // background: #fff;
            border-radius: 50%;
            border: 2px solid #fff;
        }

        .slick-dots {
            bottom: -3em;
        }

        .slick-dots li {
            display: inline-block;
            position: relative;
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }

        .slick-dots li.slick-active {
            // border: 0px solid #fff;
            background: white;
        }
    `;

const Team = () => {
    const data = useSelector(store => store.team);

    const slider_settings = {
        arrows: false,
        dots: true,
        // dotsClass: "cdots",
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        // focusOnSelect: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1670,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1330,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const teamItems = data.members.map((el, id) => <TeamItem key={id} {...el} />);

    return (
        <div className={s.main} >

            <div className={s.title}>{ data.title }</div>
            <div className={s.description}>{ data.desc }</div>
            
            <div className={s.content}>
                <StyledSlider {...slider_settings} className={s.slider}>
                    {teamItems}
                </StyledSlider>
            </div>

        </div>
    )
}

export default Team;