import React from 'react';
import { useSelector } from 'react-redux';
import AnimaBug from './animaBug/AnimaBug';
import s from './Loader.module.scss';
import ProgressBar from './progressBar/ProgressBar';

const Loader = () => {
    const count = useSelector(store => store.loaderProgress.count)
    const loaded = useSelector(store => store.loaderProgress.loaded)
    const progress = 100/count * loaded;

    return (
        <div className={s.main}>
            <div className={s.wrapper}>
                <AnimaBug />
                <ProgressBar progress={progress} />
            </div>
        </div>
    );

}

export default Loader;