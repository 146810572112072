import React from 'react';
import SocialLinkItem from './horizontalSocialLinkItem/HorizontalSocialLinkItem';
import s from './HorizontalSocialLinksPanel.module.scss';
import { useSelector } from 'react-redux';

const HorizontalSocialLinksPanel = ({ title }) => {

    const itemsData = useSelector(store => store.socialLinksDatas);
    const items = itemsData.map((el, index) => <SocialLinkItem key={index} {...el} /> );

    return (
        <div className={s.main}>
            <div className={s.title}>{title}</div>
            <div className={s.items}>
                {items}
            </div>
        </div>
    )
}

export default HorizontalSocialLinksPanel;