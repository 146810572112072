import React from 'react';
import s from './FooterNavItem.module.scss';

const FooterNavItem = (props) => {
    const { id, title, goToPage } = props;

    const onClick = (id, callback) => {
        callback(id);
    }

    return (
        <div className={s.main} >
            <div className={s.nav__item}>
                <div className={s.navlink} onClick={e => onClick(id, goToPage)} > {title} </div>
            </div>
        </div>
    )
}

export default FooterNavItem;