import React from "react";
import { useSelector } from "react-redux";
import useImagePreloader from "../../hooks/useImagePreloader";
import Loader from "../loader/Loader";
import Scroller from "../scroller/Scroller";
import { useMediaQuery } from 'react-responsive';

const MainPageContentLoader = () => {
  const isMob = useMediaQuery({ query: '(max-width: 1024px)' });
  const socialLinkData = [];//useSelector(store => state.socialLinksDatas.desc);
  const dPreloadSrcList = useSelector(store => store.preloadImagesList.desctop);
  const mPreloadSrcList = useSelector(store => store.preloadImagesList.mob);

  let preloadSrcList = dPreloadSrcList;
  if(isMob) preloadSrcList = mPreloadSrcList;

  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  if (!imagesPreloaded) {
    return <Loader/>;
  }

  return <Scroller socialLinkData={socialLinkData} />;
};

export default MainPageContentLoader;
