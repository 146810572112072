import React from 'react';
import s from './GemContent.module.scss';
import GemView from './gemView/GemView';
// import LeftPart from './leftParth/LeftPart';

import FeatureItem from './featureItem/FeatureItem';
import NLeftPart from './nLeftParth/NLeftPart';
import { useSelector } from 'react-redux';
import MBttn from '../../mBttn/MBttn';

const GemContent = () => {
    const data = useSelector(store => store.gem.content);
    const bttnText = useSelector(store => store.gem.bttnText);

    const leftItems = data.left.items.map((el, index) => {
        return (
            <FeatureItem key={index} {...el} id={index} position='left'/>
        );
    });

    const rightItems = data.right.items.map((el, index) => {
        return (
            <FeatureItem key={index} {...el} id={index} position='right'/>
        );
    });

    return (
        <div className={s.main}>
            <div className={s.content}>
                
                <NLeftPart title={data.left.title} position='left'>
                    {leftItems}
                </NLeftPart>
                
                <img className={s.leftArrow} src={data.left.arrowsImage} alt="" />
                <GemView />
                <img className={s.rightArrow} src={data.right.arrowsImage} alt="" />

                
                <NLeftPart title={data.right.title} position='left'>
                    {rightItems}
                </NLeftPart>

            </div>

            <div className={s.bttn}>
                <MBttn title={bttnText} url='https://google.com/' />
            </div>
        </div>
    )

}

export default GemContent;