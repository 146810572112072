import React from 'react';
import ContractAdressLine from '../contractAdressLine/ContractAdressLine';
import s from './ContactAdressGorizontalList.module.scss';

const ContactAdressGorizontalList = (props) => {
    const { title, items, titleInLine, titleStyle, listId } = props;

    const adrItems = items.map((el, index) => <ContractAdressLine key={index} {...el} />);
    return (
        <div className={s.main + (titleInLine ? ' ' + s.inLine : '')}>

            <div className={s.contentWrapper + (titleInLine ? ' ' + s.inLine : '')}>
                <div className={s.info__contracts_title + (titleInLine ? ' ' + s.inLine : '')} style={titleStyle}>{title} Contract Address</div>

                <div className={s.info__contracts_items + (titleInLine ? ' ' + s.inLine : '')}>
                    {adrItems}
                </div>
            </div>

        </div>
    )
}

export default ContactAdressGorizontalList;