import React from 'react';
import s from './CoinView.module.scss';
import { useSelector } from 'react-redux';

// import videoz from '../../../../resources/video/coin2_3.mp4';
// import poster from '../../../../resources/video/prev2_3.png';

const CoinView = () => {
    const img = useSelector(store => store.coin.mainIconView);
    const glowIcon = useSelector(store => store.coin.glowIcon);
    const blinkIcon = useSelector(store => store.coin.blinkIcon);
    return (
        <div className = {s.main}>
            {/* <img src={img} alt="" className={s.img}/> */}

            <div className={s.content}>

                <img src={img} alt="" className={s.img}/>

                <div className={s.bgCont}>
                    <img className={`${s.bg}`} src={glowIcon} alt="" />
                </div>

                <div className={s.decor}>
                    <img className={`${s.blink} ${s['a0']}`} src={blinkIcon} alt="" />
                </div>

                <div className={s.decor}>
                    <img className={`${s.blink} ${s['a1']}`} src={blinkIcon} alt="" />
                </div>

                <div className={s.decor}>
                    <img className={`${s.blink} ${s['a2']}`} src={blinkIcon} alt="" />
                </div>

                <div className={s.decor}>
                    <img className={`${s.blink} ${s['a3']}`} src={blinkIcon} alt="" />
                </div>

                <div className={s.decor}>
                    <img className={`${s.blink} ${s['a4']}`} src={blinkIcon} alt="" />
                </div>

                <div className={s.decor}>
                    <img className={`${s.blink} ${s['a5']}`} src={blinkIcon} alt="" />
                </div>

            </div>

            {/* <video className={s.video} poster={poster} preload="none" autoPlay loop muted playsInline preload='yes' controlsList="nodownload">
                <source src={videoz} type='video/mp4' />
            </video> */}
        </div>
    )

}

export default CoinView;