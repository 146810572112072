import React from 'react';
import SkillItem from './skilItem/SkillItem';
import s from './TeamItem.module.scss';

const TeamItem = ({ image, name, description, link, skills }) => {
    const skillItems = skills.map((el, id) => <SkillItem key={id} skill={el} />);
    return (
        <div className={s.main} >
            <img className={s.img} src={image} alt={name} />
            <div className={s.content}>
                <div className={s.card}>
                    <div className={s.name}>{ name }</div>
                    <div className={s.desc}>{ description }</div>
                    <div className={s.skills}>
                        { skillItems }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamItem;