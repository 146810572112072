import React from 'react';
import s from './CardItem.module.scss';
import arrow from '../../../../resources/images/team/green_arrow.png';
import { motion } from "framer-motion";

const CardItem = ({ step, id, type, pointerSpeed, pointDelay, pointDuration }) => {
    
    return (
        <div className={s.main} >
            <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            // transition={{ type: type, delay: (pointerSpeed * 2) + pointDelay, duration: pointDuration }}
            variants={{
                visible: { opacity: 1, transition: { type: type, delay: (pointerSpeed * id) + pointDelay, duration: pointDuration } },
                hidden: { opacity: 0, transition: { type: type, delay: (pointerSpeed * id) + pointDelay, duration: 0 } }
            }}>

                <div className={s.content}>
                    <img className={s.arrow} src={arrow} alt="" />
                    <div className={s.skill}>{ step }</div>
                </div>

            </motion.div>
            
        </div>
    )
}

export default CardItem;